<template>
  <footer
    class="flex flex-col flex-nowrap items-center justify-between gap-5 rounded-none border-t md:border bg-white px-8 py-4  text-gray-600 dark:text-gray-300 drop-shadow-md sm:flex-row sm:rounded-lg dark:border-neutral-800 dark:bg-neutral-950 dark:drop-shadow-none"
  >
    <div class="flex flex-col items-center text-sm sm:items-start">
      <span>© 2024 {{ $t('home.hero.myName') }}</span>
      <!--  <span>{{ $t('home.footer.copyrightText') }}</span> -->
    </div>
    <div class="flex flex-row gap-5">
      <UTooltip text="All Systems normal" :popper="{ placement: 'top' }">
        <a
          ref="noopener noreferrer"
          href="https://status.jittiphat.site"
          target="_blank"
          aria-label=""
        >
          <div class="hover:text-black active:scale-95 dark:hover:text-white">
            <Icon
              name="ph:cell-signal-full"
              size="32px"
              mode="svg"
            />
          </div>
        </a>
      </UTooltip>
      <UTooltip text="GitHub" :popper="{ placement: 'top' }">
        <a
          ref="noopener noreferrer"
          href="https://github.com/Jittiphatz"
          target="_blank"
          aria-label="Go to GitHub Page"
        >
          <div class="hover:text-black active:scale-95 dark:hover:text-white">
            <Icon
              name="ph:github-logo-duotone"
              size="28px"
              mode="svg"
            />
          </div>
        </a>
      </UTooltip>
      <UTooltip text="Contact Me" :popper="{ placement: 'top' }">
        <a
          ref="noopener noreferrer"
          href="mailto:Jittiphat@jittiphat.site"
          target="_blank"
          aria-label="Contact Me"
        >
          <div class="hover:text-black active:scale-95 dark:hover:text-white">
            <Icon
              name="ph:envelope"
              size="32px"
              mode="svg"
            />
          </div>
        </a>
      </UTooltip>
    </div>
  </footer>
</template>
