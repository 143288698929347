<script setup lang="ts">
const props = withDefaults(defineProps<{
  accelerate?: boolean
  color?: string
}>(), {
  accelarate: false,
  color: '#00DC82',
})
</script>

<template>
  <div class="stars w-screen h-screen absolute pointer-events-none inset-x-0 bottom-0 opacity-75" :class="{ accelerate: props.accelerate }">
    <div class="rounded-full bg-transparent" />
    <div class="rounded-full bg-transparent" />
    <div class="rounded-full bg-transparent" />
  </div>
</template>

<style scoped>
.stars {
  left: 50%;
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(217, 217, 217, 0.8) 25%,
    #d9d9d9 50%,
    rgba(217, 217, 217, 0.8) 75%,
    rgba(217, 217, 217, 0) 100%
  );
  mask-image: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(217, 217, 217, 0.8) 25%,
    #d9d9d9 50%,
    rgba(217, 217, 217, 0.8) 75%,
    rgba(217, 217, 217, 0) 100%
  );
  -webkit-mask-size: cover;
  mask-size: cover;
  transform: translate(-50%);

  --color: v-bind(props.color);
}

.stars > div {
  animation: risingStarsAnination linear infinite;
  width: 2px;
  height: 2px;
}
.stars.accelerate > div {
  width: 2px;
  height: 2px;
}

.stars div:nth-child(1) {
  animation-duration: 100s !important;
  box-shadow:
    693px 8435px var(--color),
    1921px 246px var(--color),
    1120px 872px var(--color),
    1614px 1319px var(--color),
    628px 1227px var(--color),
    144px 1298px var(--color),
    1152px 1529px var(--color),
    1486px 1897px var(--color),
    401px 1213px var(--color),
    1023px 73px var(--color),
    122px 509px var(--color),
    1264px 1265px var(--color),
    235px 1276px var(--color),
    514px 1138px var(--color),
    1019px 1915px var(--color),
    1214px 1058px var(--color),
    1740px 1354px var(--color),
    1947px 791px var(--color),
    1046px 438px var(--color),
    695px 1209px var(--color),
    58px 442px var(--color),
    1143px 1489px var(--color),
    1486px 607px var(--color),
    730px 1396px var(--color),
    402px 1177px var(--color),
    1533px 657px var(--color),
    1436px 657px var(--color),
    1567px 1081px var(--color),
    1632px 295px var(--color),
    460px 1293px var(--color),
    196px 800px var(--color),
    1554px 1284px var(--color),
    645px 1441px var(--color),
    467px 502px var(--color),
    1413px 497px var(--color),
    1468px 434px var(--color),
    34px 619px var(--color),
    1858px 1004px var(--color),
    834px 511px var(--color),
    693px 916px var(--color),
    330px 91px var(--color),
    1686px 1366px var(--color),
    36px 56px var(--color),
    390px 1355px var(--color),
    656px 99px var(--color),
    1695px 1869px var(--color),
    321px 1219px var(--color),
    271px 1082px var(--color),
    1730px 407px var(--color),
    1769px 1790px var(--color),
    1072px 780px var(--color),
    630px 1561px var(--color),
    1238px 1991px var(--color),
    26px 440px var(--color),
    998px 1604px var(--color),
    1865px 631px var(--color),
    289px 1635px var(--color),
    689px 547px var(--color),
    1691px 1147px var(--color),
    113px 1933px var(--color),
    1656px 949px var(--color),
    903px 1220px var(--color),
    1214px 503px var(--color),
    505px 1391px var(--color),
    1300px 1766px var(--color),
    56px 194px var(--color),
    1920px 572px var(--color),
    1704px 945px var(--color),
    1627px 278px var(--color),
    1659px 793px var(--color),
    1249px 1495px var(--color),
    1029px 1187px var(--color),
    1555px 408px var(--color),
    1030px 250px var(--color),
    725px 1744px var(--color),
    1787px 1643px var(--color),
    627px 199px var(--color),
    1151px 698px var(--color),
    1862px 81px var(--color),
    166px 1098px var(--color),
    896px 1022px var(--color),
    1313px 123px var(--color),
    1097px 1047px var(--color),
    1288px 1258px var(--color),
    691px 348px var(--color),
    573px 862px var(--color),
    277px 1220px var(--color),
    1039px 1041px var(--color),
    105px 634px var(--color),
    389px 1046px var(--color),
    1452px 914px var(--color),
    1636px 1264px var(--color),
    1809px 1362px var(--color),
    1796px 1460px var(--color),
    758px 131px var(--color),
    1116px 891px var(--color),
    837px 844px var(--color),
    996px 497px var(--color),
    270px 990px var(--color),
    1646px 1943px var(--color),
    1896px 734px var(--color),
    1237px 154px var(--color),
    795px 796px var(--color),
    1773px 1527px var(--color),
    1262px 1316px var(--color),
    1949px 499px var(--color),
    1987px 1438px var(--color),
    993px 1583px var(--color),
    1371px 423px var(--color),
    1596px 388px var(--color),
    878px 498px var(--color),
    1571px 1310px var(--color),
    1339px 290px var(--color),
    1323px 1028px var(--color),
    1224px 1001px var(--color),
    1211px 1638px var(--color),
    1877px 85px var(--color),
    300px 787px var(--color),
    1699px 1559px var(--color),
    506px 8px var(--color),
    703px 228px var(--color),
    1903px 233px var(--color),
    1740px 963px var(--color),
    1901px 1551px var(--color),
    34px 440px var(--color),
    660px 713px var(--color),
    953px 1397px var(--color),
    1292px 1730px var(--color),
    1733px 922px var(--color),
    844px 1972px var(--color),
    292px 85px var(--color),
    786px 284px var(--color),
    1716px 1293px var(--color),
    111px 1807px var(--color),
    1787px 1462px var(--color),
    1190px 1837px var(--color),
    224px 1552px var(--color),
    1532px 1693px var(--color),
    819px 594px var(--color),
    1441px 1037px var(--color),
    11px 1657px var(--color),
    580px 645px var(--color),
    1469px 330px var(--color),
    1532px 1057px var(--color),
    1146px 860px var(--color),
    1438px 784px var(--color),
    1365px 1858px var(--color),
    271px 1335px var(--color),
    1118px 712px var(--color),
    1261px 48px var(--color),
    1045px 447px var(--color),
    1325px 1454px var(--color),
    1517px 406px var(--color),
    1339px 386px var(--color),
    1375px 1652px var(--color),
    1719px 966px var(--color),
    581px 765px var(--color),
    1222px 599px var(--color),
    1567px 666px var(--color),
    1154px 1378px var(--color),
    1709px 480px var(--color),
    1862px 285px var(--color),
    286px 1888px var(--color),
    587px 870px var(--color),
    46px 832px var(--color),
    1036px 286px var(--color),
    1376px 261px var(--color),
    1794px 981px var(--color),
    1530px 1004px var(--color),
    1700px 1617px var(--color),
    1810px 253px var(--color),
    578px 521px var(--color),
    37px 1207px var(--color),
    1126px 151px var(--color),
    644px 603px var(--color),
    1897px 1995px var(--color),
    415px 579px var(--color),
    929px 718px var(--color),
    1025px 1952px var(--color),
    1779px 1959px var(--color),
    1955px 1752px var(--color),
    1991px 136px var(--color),
    659px 1463px var(--color),
    1113px 1052px var(--color),
    1628px 1806px var(--color),
    1891px 1064px var(--color),
    1782px 1329px var(--color),
    510px 526px var(--color),
    1348px 599px var(--color),
    1157px 1994px var(--color),
    936px 260px var(--color),
    227px 251px var(--color),
    24px 1290px var(--color),
    1944px 701px var(--color),
    1665px 745px var(--color),
    1648px 1817px var(--color),
    1446px 1941px var(--color),
    1552px 802px var(--color),
    483px 264px var(--color),
    974px 537px var(--color),
    966px 941px var(--color),
    1960px 1542px var(--color),
    281px 930px var(--color),
    1000px 863px var(--color),
    800px 1257px var(--color),
    1949px 1845px var(--color),
    43px 1126px var(--color),
    981px 36px var(--color),
    1355px 1949px var(--color),
    797px 43px var(--color),
    110px 906px var(--color),
    829px 853px var(--color),
    845px 1999px var(--color),
    565px 1058px var(--color),
    1424px 1028px var(--color),
    903px 1574px var(--color),
    1670px 632px var(--color),
    1417px 1997px var(--color),
    592px 1967px var(--color),
    843px 1894px var(--color),
    111px 341px var(--color),
    1868px 1896px var(--color),
    1846px 832px var(--color),
    1627px 1557px var(--color),
    1331px 723px var(--color),
    707px 770px var(--color),
    1589px 1422px var(--color),
    1960px 1137px var(--color),
    304px 572px var(--color),
    1275px 459px var(--color),
    707px 866px var(--color),
    914px 1524px var(--color),
    796px 551px var(--color),
    896px 1655px var(--color),
    1051px 182px var(--color),
    1834px 636px var(--color),
    278px 1663px var(--color),
    1197px 1203px var(--color),
    482px 1601px var(--color),
    199px 924px var(--color),
    1974px 1084px var(--color),
    1676px 668px var(--color),
    858px 1226px var(--color),
    1375px 1873px var(--color),
    415px 1817px var(--color),
    1112px 312px var(--color),
    1011px 1629px var(--color),
    1217px 991px var(--color),
    1418px 1998px var(--color),
    1645px 1226px var(--color),
    186px 1661px var(--color),
    1614px 1374px var(--color),
    732px 544px var(--color),
    458px 72px var(--color),
    1137px 1584px var(--color),
    1704px 1103px var(--color),
    329px 1958px var(--color),
    17px 141px var(--color),
    308px 1980px var(--color),
    1975px 675px var(--color),
    555px 330px var(--color),
    787px 712px var(--color),
    1062px 39px var(--color),
    1586px 1771px var(--color),
    1022px 1632px var(--color),
    559px 1316px var(--color),
    1254px 294px var(--color),
    799px 865px var(--color),
    672px 116px var(--color),
    1994px 575px var(--color),
    1733px 652px var(--color),
    1233px 894px var(--color),
    1369px 573px var(--color),
    237px 262px var(--color),
    1980px 200px var(--color),
    657px 1489px var(--color),
    1871px 1364px var(--color),
    1571px 891px var(--color),
    115px 617px var(--color),
    1711px 1178px var(--color),
    463px 183px var(--color),
    1148px 841px var(--color),
    566px 782px var(--color),
    844px 1056px var(--color),
    811px 1855px var(--color),
    97px 842px var(--color),
    1097px 10px var(--color),
    886px 1066px var(--color),
    1590px 1707px var(--color),
    1884px 1313px var(--color),
    1719px 408px var(--color),
    256px 319px var(--color),
    116px 253px var(--color),
    132px 1237px var(--color),
    912px 1912px var(--color),
    568px 1053px var(--color),
    840px 1523px var(--color),
    521px 1780px var(--color),
    1092px 554px var(--color),
    1833px 1018px var(--color),
    1897px 514px var(--color),
    1765px 1236px var(--color),
    415px 1512px var(--color),
    1457px 1382px var(--color),
    567px 1294px var(--color),
    777px 505px var(--color),
    1502px 635px var(--color),
    422px 933px var(--color),
    1609px 1030px var(--color),
    124px 550px var(--color),
    1839px 895px var(--color),
    671px 1187px var(--color),
    1445px 1668px var(--color),
    1457px 633px var(--color),
    363px 1250px var(--color),
    1770px 1598px var(--color),
    1802px 82px var(--color),
    1784px 1798px var(--color),
    1275px 1504px var(--color),
    888px 264px var(--color),
    157px 209px var(--color),
    1803px 1572px var(--color),
    722px 851px var(--color),
    732px 365px var(--color),
    485px 1277px var(--color),
    155px 974px var(--color),
    986px 339px var(--color),
    283px 49px var(--color),
    1605px 727px var(--color),
    55px 359px var(--color),
    468px 987px var(--color),
    789px 283px var(--color),
    97px 623px var(--color),
    237px 432px var(--color),
    577px 619px var(--color),
    891px 623px var(--color),
    861px 373px var(--color),
    510px 1655px var(--color),
    808px 1413px var(--color),
    1626px 1241px var(--color),
    368px 53px var(--color),
    796px 852px var(--color),
    1832px 152px var(--color),
    575px 1066px var(--color),
    1374px 1739px var(--color),
    951px 1313px var(--color),
    1305px 742px var(--color),
    607px 1560px var(--color),
    1735px 682px var(--color),
    827px 805px var(--color),
    270px 1759px var(--color),
    606px 1115px var(--color),
    569px 1730px var(--color),
    742px 158px var(--color),
    261px 1861px var(--color),
    1926px 1332px var(--color),
    461px 1982px var(--color),
    380px 855px var(--color),
    194px 1920px var(--color),
    423px 228px var(--color),
    498px 75px var(--color),
    480px 27px var(--color),
    1842px 769px var(--color),
    1587px 605px var(--color),
    721px 371px var(--color),
    1248px 20px var(--color),
    457px 1734px var(--color),
    1642px 895px var(--color),
    37px 282px var(--color),
    139px 856px var(--color),
    1863px 1254px var(--color),
    728px 419px var(--color),
    1930px 1638px var(--color),
    587px 1392px var(--color),
    52px 228px var(--color),
    483px 1150px var(--color),
    1127px 970px var(--color),
    20px 1909px var(--color),
    1427px 1208px var(--color),
    421px 951px var(--color),
    1552px 259px var(--color),
    688px 1070px var(--color),
    1618px 1896px var(--color),
    1422px 942px var(--color),
    902px 741px var(--color),
    340px 916px var(--color),
    1392px 1128px var(--color),
    1713px 539px var(--color),
    1213px 1312px var(--color),
    170px 1565px var(--color),
    1603px 719px var(--color),
    1504px 1666px var(--color),
    880px 440px var(--color),
    1611px 1247px var(--color),
    408px 1211px var(--color),
    624px 371px var(--color),
    1309px 531px var(--color),
    1913px 955px var(--color),
    170px 1921px var(--color),
    40px 1967px var(--color),
    1074px 1194px var(--color),
    1025px 632px var(--color),
    165px 277px var(--color),
    1685px 1842px var(--color),
    1840px 1925px var(--color),
    1534px 859px var(--color),
    411px 780px var(--color),
    1814px 618px var(--color),
    802px 1791px var(--color),
    724px 1305px var(--color),
    692px 603px var(--color),
    662px 1720px var(--color),
    682px 333px var(--color),
    962px 170px var(--color),
    845px 1277px var(--color),
    1692px 650px var(--color),
    810px 1576px var(--color),
    1812px 1536px var(--color),
    1031px 641px var(--color),
    1794px 1450px var(--color),
    496px 1923px var(--color),
    1410px 998px var(--color),
    1984px 1904px var(--color),
    989px 1970px var(--color),
    926px 1826px var(--color),
    1063px 1831px var(--color),
    586px 59px var(--color),
    769px 1816px var(--color),
    789px 993px var(--color),
    1228px 1880px var(--color),
    12px 1624px var(--color),
    1706px 787px var(--color),
    763px 1189px var(--color),
    1066px 1139px var(--color),
    1927px 182px var(--color),
    1058px 927px var(--color),
    463px 938px var(--color),
    1608px 564px var(--color),
    681px 1082px var(--color),
    1681px 1704px var(--color),
    499px 1586px var(--color),
    1270px 184px var(--color),
    731px 233px var(--color),
    500px 916px var(--color),
    1488px 1864px var(--color),
    38px 1593px var(--color),
    155px 639px var(--color),
    1210px 435px var(--color),
    671px 1152px var(--color),
    1671px 473px var(--color),
    424px 101px var(--color),
    1456px 1419px var(--color),
    1791px 1837px var(--color),
    1783px 411px var(--color),
    1355px 11px var(--color),
    1840px 536px var(--color),
    555px 538px var(--color),
    71px 683px var(--color),
    1375px 674px var(--color),
    269px 545px var(--color),
    1523px 79px var(--color),
    1960px 602px var(--color),
    828px 732px var(--color),
    723px 771px var(--color),
    712px 1505px var(--color),
    285px 606px var(--color),
    286px 1741px var(--color),
    1233px 778px var(--color),
    1717px 1221px var(--color),
    1225px 981px var(--color),
    691px 752px var(--color),
    829px 78px var(--color),
    1211px 1365px var(--color),
    1701px 1323px var(--color),
    1614px 87px var(--color),
    1974px 1744px var(--color),
    1227px 873px var(--color),
    549px 1357px var(--color),
    881px 1414px var(--color),
    321px 1381px var(--color),
    1378px 1339px var(--color),
    1331px 1263px var(--color),
    1997px 50px var(--color),
    149px 776px var(--color),
    1297px 1054px var(--color),
    1183px 757px var(--color),
    421px 58px var(--color),
    1847px 217px var(--color),
    1128px 1490px var(--color),
    681px 485px var(--color),
    486px 1455px var(--color),
    224px 804px var(--color),
    1971px 210px var(--color),
    699px 1124px var(--color),
    1210px 799px var(--color),
    521px 18px var(--color),
    1793px 1493px var(--color),
    36px 815px var(--color),
    1776px 462px var(--color),
    1701px 196px var(--color);
  opacity: 0.75;
}
.stars.accelerate div:nth-child(1) {
  opacity: 1;
  animation-duration: 10s !important;
}

.stars div:nth-child(2) {
  animation-duration: 150s !important;
  box-shadow:
    1432px 8687px var(--color),
    281px 1550px var(--color),
    797px 588px var(--color),
    1219px 1750px var(--color),
    291px 20px var(--color),
    989px 1462px var(--color),
    321px 1536px var(--color),
    660px 1179px var(--color),
    630px 1097px var(--color),
    138px 1129px var(--color),
    317px 982px var(--color),
    1005px 692px var(--color),
    594px 770px var(--color),
    817px 1325px var(--color),
    1750px 516px var(--color),
    306px 784px var(--color),
    103px 1251px var(--color),
    1035px 897px var(--color),
    1598px 760px var(--color),
    1671px 1684px var(--color),
    473px 120px var(--color),
    1871px 422px var(--color),
    1416px 1596px var(--color),
    664px 469px var(--color),
    1706px 1842px var(--color),
    1830px 647px var(--color),
    1494px 831px var(--color),
    386px 209px var(--color),
    1804px 1120px var(--color),
    439px 1000px var(--color),
    1089px 7px var(--color),
    1293px 560px var(--color),
    102px 1926px var(--color),
    1219px 1667px var(--color),
    1364px 1107px var(--color),
    1270px 619px var(--color),
    1180px 1657px var(--color),
    1208px 521px var(--color),
    807px 1325px var(--color),
    478px 396px var(--color),
    995px 327px var(--color),
    1067px 352px var(--color),
    1620px 1648px var(--color),
    114px 1380px var(--color),
    24px 883px var(--color),
    1589px 883px var(--color),
    1365px 139px var(--color),
    778px 1926px var(--color),
    1390px 1943px var(--color),
    1755px 193px var(--color),
    1575px 292px var(--color),
    214px 1095px var(--color),
    372px 1011px var(--color),
    251px 31px var(--color),
    1249px 1150px var(--color),
    1481px 520px var(--color),
    1716px 499px var(--color),
    937px 1934px var(--color),
    743px 494px var(--color),
    1341px 1953px var(--color),
    670px 1523px var(--color),
    441px 940px var(--color),
    1056px 944px var(--color),
    1963px 1467px var(--color),
    1543px 852px var(--color),
    225px 763px var(--color),
    957px 1937px var(--color),
    1344px 460px var(--color),
    497px 512px var(--color),
    1676px 1196px var(--color),
    961px 438px var(--color),
    1721px 1580px var(--color),
    418px 1874px var(--color),
    316px 1597px var(--color),
    1897px 526px var(--color),
    640px 1420px var(--color),
    50px 1211px var(--color),
    1871px 726px var(--color),
    1672px 1990px var(--color),
    1258px 864px var(--color),
    62px 614px var(--color),
    314px 882px var(--color),
    878px 1076px var(--color),
    1724px 1142px var(--color),
    543px 46px var(--color),
    641px 425px var(--color),
    76px 57px var(--color),
    227px 927px var(--color),
    816px 1130px var(--color),
    1064px 1389px var(--color),
    1915px 671px var(--color),
    407px 1527px var(--color),
    257px 605px var(--color),
    983px 1012px var(--color),
    1576px 388px var(--color),
    1575px 1935px var(--color),
    68px 1594px var(--color),
    311px 107px var(--color),
    832px 144px var(--color),
    349px 1401px var(--color),
    1150px 1652px var(--color),
    627px 1663px var(--color),
    922px 1119px var(--color),
    720px 1629px var(--color),
    305px 642px var(--color),
    1125px 146px var(--color),
    898px 750px var(--color),
    642px 1538px var(--color),
    88px 782px var(--color),
    723px 1320px var(--color),
    355px 785px var(--color),
    1149px 1949px var(--color),
    1522px 1885px var(--color),
    1984px 957px var(--color),
    256px 1165px var(--color),
    1216px 1415px var(--color),
    777px 408px var(--color),
    819px 510px var(--color),
    1239px 1325px var(--color),
    1202px 326px var(--color),
    225px 384px var(--color),
    291px 498px var(--color),
    1349px 535px var(--color),
    1927px 1931px var(--color),
    1673px 507px var(--color),
    584px 1285px var(--color),
    486px 743px var(--color),
    1391px 1091px var(--color),
    541px 947px var(--color),
    1505px 1612px var(--color),
    231px 1371px var(--color),
    1336px 1722px var(--color),
    729px 1068px var(--color),
    1179px 1525px var(--color),
    203px 76px var(--color),
    1878px 736px var(--color),
    1704px 1381px var(--color),
    551px 1527px var(--color),
    1110px 1859px var(--color),
    313px 596px var(--color),
    543px 9px var(--color),
    1091px 951px var(--color),
    1178px 1081px var(--color),
    137px 1930px var(--color),
    705px 1554px var(--color),
    1427px 1999px var(--color),
    988px 1968px var(--color),
    271px 1720px var(--color),
    1234px 1857px var(--color),
    1559px 1284px var(--color),
    1353px 1409px var(--color),
    298px 1591px var(--color),
    602px 1198px var(--color),
    1451px 106px var(--color),
    1835px 1119px var(--color),
    1098px 324px var(--color),
    946px 842px var(--color),
    1308px 1879px var(--color),
    1013px 57px var(--color),
    1856px 1163px var(--color),
    266px 1889px var(--color),
    361px 657px var(--color),
    1450px 873px var(--color),
    1008px 644px var(--color),
    632px 1115px var(--color),
    45px 1126px var(--color),
    845px 1090px var(--color),
    582px 974px var(--color),
    671px 82px var(--color),
    561px 1442px var(--color),
    1417px 594px var(--color),
    931px 984px var(--color),
    1677px 1052px var(--color),
    1698px 844px var(--color),
    1043px 1059px var(--color),
    861px 1672px var(--color),
    1922px 1111px var(--color),
    1261px 310px var(--color),
    1940px 1966px var(--color),
    391px 1960px var(--color),
    1116px 158px var(--color),
    639px 1471px var(--color),
    526px 1349px var(--color),
    888px 1053px var(--color),
    547px 563px var(--color),
    1005px 1053px var(--color),
    67px 98px var(--color),
    9px 872px var(--color),
    693px 1422px var(--color),
    1303px 1524px var(--color),
    1765px 655px var(--color),
    66px 188px var(--color),
    1929px 1145px var(--color),
    1158px 1423px var(--color),
    1897px 302px var(--color),
    1421px 630px var(--color),
    120px 240px var(--color),
    777px 1748px var(--color),
    802px 1919px var(--color),
    1039px 745px var(--color),
    1739px 897px var(--color),
    327px 972px var(--color),
    426px 1450px var(--color),
    1900px 1785px var(--color),
    431px 87px var(--color),
    394px 1871px var(--color),
    226px 1776px var(--color),
    698px 757px var(--color),
    944px 274px var(--color),
    1764px 899px var(--color),
    736px 858px var(--color),
    1581px 867px var(--color),
    1760px 594px var(--color),
    315px 1572px var(--color),
    612px 69px var(--color),
    491px 1784px var(--color),
    1487px 1998px var(--color),
    371px 1477px var(--color),
    1770px 638px var(--color),
    606px 1666px var(--color),
    347px 1014px var(--color),
    659px 585px var(--color),
    1762px 1819px var(--color),
    1795px 823px var(--color),
    486px 1488px var(--color),
    1424px 977px var(--color),
    1441px 1377px var(--color),
    372px 1103px var(--color),
    1736px 1114px var(--color),
    883px 789px var(--color),
    760px 456px var(--color),
    1475px 1368px var(--color),
    343px 415px var(--color),
    1221px 823px var(--color),
    894px 513px var(--color),
    1016px 713px var(--color),
    751px 22px var(--color),
    875px 595px var(--color),
    1999px 1266px var(--color),
    1340px 652px var(--color),
    1872px 540px var(--color),
    1957px 753px var(--color),
    308px 1242px var(--color),
    1551px 1124px var(--color),
    1417px 305px var(--color),
    1701px 1843px var(--color),
    539px 1505px var(--color),
    404px 1442px var(--color),
    146px 248px var(--color),
    1942px 602px var(--color),
    1019px 1240px var(--color),
    813px 1300px var(--color),
    1259px 1092px var(--color),
    1820px 10px var(--color),
    27px 222px var(--color),
    278px 1838px var(--color),
    1420px 1702px var(--color),
    367px 61px var(--color),
    433px 518px var(--color),
    253px 1444px var(--color),
    791px 954px var(--color),
    1768px 1504px var(--color),
    709px 1529px var(--color),
    198px 246px var(--color),
    1228px 131px var(--color),
    812px 1859px var(--color),
    1145px 784px var(--color),
    165px 377px var(--color),
    847px 535px var(--color),
    71px 1404px var(--color),
    1364px 1168px var(--color),
    410px 1120px var(--color),
    1265px 261px var(--color),
    1678px 1845px var(--color),
    305px 873px var(--color),
    1524px 1720px var(--color),
    608px 682px var(--color),
    1277px 991px var(--color),
    1865px 1097px var(--color),
    1426px 197px var(--color),
    435px 1774px var(--color),
    42px 470px var(--color),
    27px 453px var(--color),
    306px 1691px var(--color),
    60px 1692px var(--color),
    1097px 1408px var(--color),
    992px 136px var(--color),
    1082px 1688px var(--color),
    1925px 1301px var(--color),
    156px 1675px var(--color),
    164px 1147px var(--color),
    109px 480px var(--color),
    1597px 1391px var(--color),
    238px 1553px var(--color),
    1609px 303px var(--color),
    1932px 1158px var(--color),
    127px 891px var(--color),
    279px 1047px var(--color),
    909px 1389px var(--color),
    1517px 1647px var(--color),
    394px 1866px var(--color),
    1351px 838px var(--color),
    787px 660px var(--color),
    138px 871px var(--color),
    1228px 1149px var(--color),
    1251px 1946px var(--color),
    1766px 1350px var(--color),
    230px 1552px var(--color),
    1412px 1835px var(--color),
    1465px 1476px var(--color),
    288px 1472px var(--color),
    1608px 1698px var(--color),
    1503px 1254px var(--color),
    888px 1080px var(--color),
    962px 1952px var(--color),
    987px 249px var(--color),
    1516px 1971px var(--color),
    598px 1683px var(--color),
    740px 12px var(--color),
    1017px 1917px var(--color),
    1094px 894px var(--color),
    596px 71px var(--color),
    745px 1504px var(--color),
    1234px 1514px var(--color),
    13px 25px var(--color),
    1981px 758px var(--color),
    714px 853px var(--color),
    1964px 637px var(--color),
    373px 1568px var(--color),
    1580px 1172px var(--color),
    130px 605px var(--color),
    1736px 1281px var(--color),
    65px 1793px var(--color),
    436px 861px var(--color),
    33px 161px var(--color),
    1530px 1981px var(--color),
    1199px 1707px var(--color),
    1118px 1175px var(--color),
    470px 1959px var(--color),
    1552px 233px var(--color),
    1450px 1039px var(--color),
    1012px 543px var(--color),
    722px 621px var(--color),
    777px 1365px var(--color),
    1510px 1339px var(--color),
    95px 896px var(--color),
    60px 1765px var(--color),
    62px 1148px var(--color),
    720px 763px var(--color),
    1434px 1114px var(--color),
    250px 400px var(--color),
    1903px 490px var(--color),
    715px 762px var(--color),
    185px 1156px var(--color),
    1993px 907px var(--color),
    389px 95px var(--color),
    347px 1342px var(--color),
    189px 1186px var(--color),
    1498px 1760px var(--color),
    1525px 358px var(--color),
    479px 1712px var(--color),
    483px 418px var(--color),
    317px 815px var(--color),
    380px 1413px var(--color),
    1486px 922px var(--color),
    1379px 894px var(--color),
    1149px 1911px var(--color),
    1360px 1819px var(--color),
    140px 1776px var(--color),
    1167px 875px var(--color),
    332px 1193px var(--color),
    1252px 1232px var(--color),
    1713px 1820px var(--color),
    455px 58px var(--color),
    1045px 555px var(--color),
    377px 1058px var(--color),
    900px 1396px var(--color),
    288px 592px var(--color),
    399px 762px var(--color),
    590px 460px var(--color),
    126px 1925px var(--color),
    1429px 890px var(--color),
    1841px 1712px var(--color),
    1975px 182px var(--color),
    1559px 1169px var(--color),
    1661px 1463px var(--color),
    1723px 1948px var(--color),
    1280px 1703px var(--color),
    713px 1205px var(--color),
    334px 1816px var(--color),
    460px 724px var(--color),
    1573px 1084px var(--color),
    1543px 1086px var(--color),
    1319px 1241px var(--color),
    1014px 880px var(--color),
    1492px 18px var(--color),
    330px 153px var(--color),
    3px 1416px var(--color),
    1396px 166px var(--color),
    1482px 1299px var(--color),
    819px 1966px var(--color),
    281px 3px var(--color),
    1072px 1199px var(--color),
    510px 456px var(--color),
    1521px 1731px var(--color),
    1801px 643px var(--color),
    446px 529px var(--color),
    1888px 1169px var(--color),
    893px 683px var(--color),
    1588px 348px var(--color),
    1748px 566px var(--color),
    232px 481px var(--color),
    769px 200px var(--color),
    1649px 1016px var(--color),
    1675px 1930px var(--color),
    1018px 1607px var(--color),
    1137px 825px var(--color),
    491px 608px var(--color),
    28px 1383px var(--color),
    329px 746px var(--color),
    273px 1846px var(--color),
    1257px 1139px var(--color),
    258px 1278px var(--color),
    821px 1393px var(--color),
    237px 1117px var(--color),
    172px 771px var(--color),
    120px 608px var(--color),
    670px 939px var(--color),
    1412px 1095px var(--color),
    1257px 1094px var(--color),
    996px 1308px var(--color),
    953px 1799px var(--color),
    769px 11px var(--color),
    1586px 864px var(--color),
    1217px 1677px var(--color),
    1276px 1159px var(--color),
    1961px 488px var(--color),
    1888px 676px var(--color),
    1965px 634px var(--color),
    1372px 1360px var(--color),
    875px 1653px var(--color),
    939px 408px var(--color),
    1632px 1895px var(--color),
    1858px 802px var(--color),
    574px 1280px var(--color),
    1800px 1240px var(--color),
    1484px 537px var(--color),
    926px 1379px var(--color),
    1436px 1123px var(--color),
    136px 506px var(--color),
    920px 1692px var(--color),
    1640px 1581px var(--color),
    426px 528px var(--color),
    1531px 45px var(--color),
    634px 991px var(--color),
    629px 319px var(--color),
    808px 1818px var(--color),
    144px 1357px var(--color),
    69px 409px var(--color),
    284px 1280px var(--color),
    1975px 546px var(--color),
    817px 261px var(--color),
    1794px 575px var(--color),
    1747px 1141px var(--color),
    997px 1824px var(--color),
    783px 1897px var(--color),
    597px 1756px var(--color),
    464px 1327px var(--color),
    1041px 1795px var(--color),
    1341px 699px var(--color),
    1155px 1249px var(--color),
    1801px 1098px var(--color),
    1224px 922px var(--color),
    1548px 85px var(--color),
    1446px 1746px var(--color),
    898px 625px var(--color),
    1374px 742px var(--color),
    1587px 1468px var(--color),
    1720px 1268px var(--color),
    776px 667px var(--color),
    867px 128px var(--color),
    841px 1808px var(--color),
    1825px 1805px var(--color),
    411px 1851px var(--color),
    455px 1033px var(--color),
    1103px 612px var(--color),
    1066px 1433px var(--color),
    1068px 835px var(--color),
    1497px 1510px var(--color),
    1517px 1457px var(--color),
    793px 363px var(--color),
    983px 613px var(--color),
    655px 1820px var(--color),
    1244px 1592px var(--color),
    625px 264px var(--color),
    744px 248px var(--color),
    423px 1810px var(--color),
    36px 428px var(--color),
    797px 489px var(--color),
    152px 1855px var(--color);
  opacity: 0.5;
}
.stars.accelerate div:nth-child(2) {
  animation-duration: 15s !important;
  opacity: 0.75;
}

.stars div:nth-child(3) {
  animation-duration: 200s !important;
  box-shadow:
    1101px 12446px var(--color),
    1029px 11px var(--color),
    1085px 961px var(--color),
    1767px 1121px var(--color),
    78px 1112px var(--color),
    1969px 440px var(--color),
    743px 1548px var(--color),
    1005px 1473px var(--color),
    1843px 1450px var(--color),
    1062px 547px var(--color),
    1105px 1501px var(--color),
    316px 1466px var(--color),
    831px 1071px var(--color),
    879px 1157px var(--color),
    1400px 1368px var(--color),
    1491px 1832px var(--color),
    833px 1040px var(--color),
    1788px 1114px var(--color),
    312px 406px var(--color),
    447px 1127px var(--color),
    312px 1249px var(--color),
    577px 1906px var(--color),
    1673px 1170px var(--color),
    926px 1822px var(--color),
    1378px 770px var(--color),
    55px 1592px var(--color),
    1377px 419px var(--color),
    577px 933px var(--color),
    668px 576px var(--color),
    1347px 1630px var(--color),
    1994px 1661px var(--color),
    856px 1426px var(--color),
    262px 1304px var(--color),
    736px 895px var(--color),
    419px 329px var(--color),
    1248px 187px var(--color),
    1584px 1841px var(--color),
    789px 603px var(--color),
    1831px 1181px var(--color),
    1972px 11px var(--color),
    567px 1408px var(--color),
    203px 1943px var(--color),
    502px 961px var(--color),
    1992px 284px var(--color),
    153px 1236px var(--color),
    1395px 491px var(--color),
    133px 153px var(--color),
    699px 61px var(--color),
    511px 1467px var(--color),
    1069px 909px var(--color),
    1534px 1962px var(--color),
    561px 866px var(--color),
    1392px 1318px var(--color),
    1524px 369px var(--color),
    195px 1687px var(--color),
    707px 1428px var(--color),
    1528px 208px var(--color),
    1658px 669px var(--color),
    1579px 161px var(--color),
    757px 766px var(--color),
    72px 4px var(--color),
    372px 1622px var(--color),
    515px 1062px var(--color),
    581px 89px var(--color),
    818px 860px var(--color),
    1949px 779px var(--color),
    1085px 1014px var(--color),
    1677px 551px var(--color),
    502px 371px var(--color),
    1034px 1401px var(--color),
    1745px 1621px var(--color),
    857px 1349px var(--color),
    946px 1184px var(--color),
    376px 1131px var(--color),
    781px 1298px var(--color),
    481px 1846px var(--color),
    1052px 370px var(--color),
    1499px 872px var(--color),
    1606px 905px var(--color),
    363px 1429px var(--color),
    319px 177px var(--color),
    1119px 1019px var(--color),
    1056px 1981px var(--color),
    64px 1192px var(--color),
    572px 1714px var(--color),
    569px 1206px var(--color),
    795px 29px var(--color),
    1125px 1133px var(--color),
    1495px 862px var(--color),
    1298px 587px var(--color),
    1938px 1234px var(--color),
    1009px 1328px var(--color),
    1882px 684px var(--color),
    682px 1022px var(--color),
    844px 936px var(--color),
    1246px 1933px var(--color),
    1044px 1128px var(--color),
    1973px 395px var(--color),
    482px 1715px var(--color),
    695px 528px var(--color),
    1806px 1908px var(--color),
    1578px 998px var(--color),
    143px 1347px var(--color),
    998px 1688px var(--color),
    890px 1063px var(--color),
    1355px 1564px var(--color),
    1349px 350px var(--color),
    1225px 1779px var(--color),
    1841px 1934px var(--color),
    314px 1913px var(--color),
    143px 1167px var(--color),
    1974px 25px var(--color),
    615px 1143px var(--color),
    416px 1967px var(--color),
    1504px 1977px var(--color),
    1017px 1660px var(--color),
    580px 1086px var(--color),
    1974px 1626px var(--color),
    1014px 1610px var(--color),
    266px 1343px var(--color),
    963px 1117px var(--color),
    82px 1048px var(--color),
    1963px 1702px var(--color),
    1294px 1845px var(--color),
    1758px 1448px var(--color),
    1829px 1691px var(--color),
    1299px 279px var(--color),
    446px 1018px var(--color),
    1228px 1952px var(--color),
    1369px 1924px var(--color),
    1416px 532px var(--color),
    263px 1688px var(--color),
    1437px 792px var(--color),
    469px 692px var(--color),
    1904px 34px var(--color),
    1618px 1580px var(--color),
    663px 1204px var(--color),
    816px 1628px var(--color),
    340px 529px var(--color),
    1404px 287px var(--color),
    1149px 1846px var(--color),
    1883px 1682px var(--color),
    1637px 1057px var(--color),
    570px 951px var(--color),
    1623px 205px var(--color),
    988px 439px var(--color),
    519px 316px var(--color),
    1510px 1127px var(--color),
    120px 115px var(--color),
    145px 853px var(--color),
    772px 1379px var(--color),
    852px 155px var(--color),
    1741px 817px var(--color),
    593px 1083px var(--color),
    456px 260px var(--color),
    527px 393px var(--color),
    1763px 1510px var(--color),
    1643px 1437px var(--color),
    997px 1537px var(--color),
    1320px 1844px var(--color),
    611px 892px var(--color),
    1242px 879px var(--color),
    1556px 1491px var(--color),
    882px 264px var(--color),
    1632px 1402px var(--color),
    206px 378px var(--color),
    745px 1042px var(--color),
    1734px 26px var(--color),
    996px 352px var(--color),
    181px 634px var(--color),
    1609px 1890px var(--color),
    1995px 1687px var(--color),
    381px 547px var(--color),
    1779px 225px var(--color),
    1790px 1496px var(--color),
    1368px 1212px var(--color),
    140px 1516px var(--color),
    992px 1470px var(--color),
    748px 98px var(--color),
    1682px 1653px var(--color),
    21px 823px var(--color),
    539px 789px var(--color),
    1158px 1232px var(--color),
    437px 1554px var(--color),
    1224px 1078px var(--color),
    262px 254px var(--color),
    1618px 349px var(--color),
    945px 1054px var(--color),
    780px 131px var(--color),
    605px 157px var(--color),
    1586px 617px var(--color),
    1120px 1794px var(--color),
    1533px 751px var(--color),
    1391px 1941px var(--color),
    1920px 1852px var(--color),
    1860px 1543px var(--color),
    1621px 695px var(--color),
    42px 1618px var(--color),
    362px 428px var(--color),
    1354px 688px var(--color),
    1347px 228px var(--color),
    1956px 1145px var(--color),
    1160px 1979px var(--color),
    124px 597px var(--color),
    1491px 1234px var(--color),
    1552px 876px var(--color),
    1066px 1057px var(--color),
    1420px 316px var(--color),
    179px 590px var(--color),
    435px 142px var(--color),
    772px 861px var(--color),
    878px 53px var(--color),
    1172px 1627px var(--color),
    1847px 580px var(--color),
    1574px 1056px var(--color),
    281px 1326px var(--color),
    443px 1159px var(--color),
    1768px 1225px var(--color),
    1883px 1368px var(--color),
    1957px 1304px var(--color),
    1666px 1523px var(--color),
    1607px 593px var(--color),
    413px 549px var(--color),
    878px 1579px var(--color),
    1041px 766px var(--color),
    638px 1178px var(--color),
    1985px 534px var(--color),
    537px 142px var(--color),
    1376px 1133px var(--color),
    990px 430px var(--color),
    1062px 839px var(--color),
    1658px 1871px var(--color),
    20px 29px var(--color),
    336px 219px var(--color),
    1793px 323px var(--color),
    1538px 176px var(--color),
    558px 1227px var(--color),
    1826px 1132px var(--color),
    572px 1461px var(--color),
    1900px 1647px var(--color),
    1126px 1972px var(--color),
    703px 358px var(--color),
    1444px 1988px var(--color),
    980px 721px var(--color),
    240px 674px var(--color),
    1731px 1607px var(--color),
    49px 293px var(--color),
    1392px 1831px var(--color),
    101px 1661px var(--color),
    1834px 1163px var(--color),
    1709px 1336px var(--color),
    88px 1546px var(--color),
    1688px 1721px var(--color),
    624px 1058px var(--color),
    1347px 352px var(--color),
    1262px 677px var(--color),
    948px 1820px var(--color),
    1222px 224px var(--color),
    365px 633px var(--color),
    747px 1162px var(--color),
    527px 1874px var(--color),
    1146px 1467px var(--color),
    949px 1127px var(--color),
    92px 1714px var(--color),
    546px 657px var(--color),
    172px 1489px var(--color),
    587px 970px var(--color),
    359px 1225px var(--color),
    641px 1px var(--color),
    1577px 926px var(--color),
    608px 111px var(--color),
    46px 1967px var(--color),
    1339px 1895px var(--color),
    723px 1320px var(--color),
    329px 1730px var(--color),
    1485px 247px var(--color),
    824px 944px var(--color),
    1441px 1462px var(--color),
    1790px 193px var(--color),
    909px 219px var(--color),
    1161px 1469px var(--color),
    1037px 501px var(--color),
    1397px 999px var(--color),
    1863px 1019px var(--color),
    1956px 406px var(--color),
    1424px 1301px var(--color),
    1036px 676px var(--color),
    1080px 1177px var(--color),
    3px 696px var(--color),
    488px 311px var(--color),
    1907px 334px var(--color),
    174px 1634px var(--color),
    1975px 1271px var(--color),
    947px 64px var(--color),
    669px 767px var(--color),
    721px 1159px var(--color),
    1119px 902px var(--color),
    1498px 2px var(--color),
    372px 434px var(--color),
    1658px 1144px var(--color),
    178px 1549px var(--color),
    1207px 1874px var(--color),
    254px 393px var(--color),
    1965px 1954px var(--color),
    1688px 1283px var(--color),
    359px 1700px var(--color),
    1518px 394px var(--color),
    77px 1612px var(--color),
    216px 387px var(--color),
    1435px 1841px var(--color),
    1110px 1563px var(--color),
    1828px 1710px var(--color),
    527px 973px var(--color),
    1471px 674px var(--color),
    486px 851px var(--color),
    926px 1590px var(--color),
    1566px 1940px var(--color),
    1638px 76px var(--color),
    1369px 1201px var(--color),
    1852px 1309px var(--color),
    675px 1153px var(--color),
    75px 1758px var(--color),
    583px 1871px var(--color),
    170px 655px var(--color),
    710px 12px var(--color),
    908px 1634px var(--color),
    63px 1219px var(--color),
    974px 1654px var(--color),
    855px 1576px var(--color),
    655px 1377px var(--color),
    980px 1494px var(--color),
    45px 100px var(--color),
    1347px 969px var(--color),
    212px 1349px var(--color),
    676px 1443px var(--color),
    1727px 738px var(--color),
    1724px 513px var(--color),
    295px 1126px var(--color),
    582px 722px var(--color),
    222px 291px var(--color),
    1264px 731px var(--color),
    1895px 1348px var(--color),
    1234px 76px var(--color),
    47px 1101px var(--color),
    774px 1279px var(--color),
    1251px 1960px var(--color),
    1113px 1422px var(--color),
    1025px 1581px var(--color),
    1195px 953px var(--color),
    1402px 1616px var(--color),
    537px 92px var(--color),
    832px 887px var(--color),
    95px 1596px var(--color),
    351px 270px var(--color),
    1231px 1562px var(--color),
    830px 364px var(--color),
    664px 128px var(--color),
    1792px 879px var(--color),
    1847px 287px var(--color),
    1140px 615px var(--color),
    122px 69px var(--color),
    1881px 1138px var(--color),
    1221px 1144px var(--color),
    338px 1372px var(--color),
    1222px 1110px var(--color),
    1245px 1334px var(--color),
    775px 824px var(--color),
    1829px 1036px var(--color),
    966px 1438px var(--color),
    1256px 1511px var(--color),
    1313px 1398px var(--color),
    1641px 1165px var(--color),
    1112px 355px var(--color),
    69px 1672px var(--color),
    1251px 453px var(--color),
    733px 244px var(--color),
    665px 527px var(--color),
    829px 975px var(--color),
    332px 1765px var(--color),
    1332px 1562px var(--color),
    1445px 803px var(--color),
    899px 1365px var(--color),
    1791px 1417px var(--color),
    1766px 1682px var(--color),
    1995px 234px var(--color),
    407px 660px var(--color),
    97px 88px var(--color),
    158px 1614px var(--color),
    1474px 321px var(--color),
    776px 889px var(--color),
    199px 83px var(--color),
    1999px 1201px var(--color),
    1591px 1090px var(--color),
    640px 404px var(--color),
    92px 1325px var(--color),
    1802px 1062px var(--color),
    389px 1731px var(--color),
    64px 1986px var(--color),
    334px 25px var(--color),
    682px 1235px var(--color),
    19px 1285px var(--color),
    761px 259px var(--color),
    1129px 494px var(--color),
    1272px 677px var(--color),
    285px 1943px var(--color),
    938px 1838px var(--color),
    1942px 66px var(--color),
    1921px 595px var(--color),
    1767px 843px var(--color),
    1018px 1857px var(--color),
    1487px 904px var(--color),
    1541px 338px var(--color),
    842px 593px var(--color),
    1643px 1017px var(--color),
    463px 329px var(--color),
    1903px 1984px var(--color),
    105px 663px var(--color),
    975px 116px var(--color),
    274px 955px var(--color),
    1421px 1158px var(--color),
    784px 596px var(--color),
    218px 796px var(--color),
    763px 1015px var(--color),
    494px 36px var(--color),
    99px 1630px var(--color),
    91px 21px var(--color),
    929px 1894px var(--color),
    670px 1560px var(--color),
    1096px 1824px var(--color),
    313px 1784px var(--color),
    1512px 251px var(--color),
    1020px 1738px var(--color),
    714px 671px var(--color),
    278px 1287px var(--color),
    1251px 886px var(--color),
    1852px 545px var(--color),
    209px 1270px var(--color),
    128px 1874px var(--color),
    853px 1429px var(--color),
    150px 1984px var(--color),
    991px 709px var(--color),
    417px 1871px var(--color),
    1439px 1133px var(--color),
    702px 1448px var(--color),
    827px 1661px var(--color),
    630px 103px var(--color),
    589px 894px var(--color),
    1178px 297px var(--color),
    1202px 1982px var(--color),
    1728px 679px var(--color),
    1936px 1470px var(--color),
    1161px 1711px var(--color),
    1661px 1092px var(--color),
    46px 1100px var(--color),
    187px 388px var(--color),
    636px 1445px var(--color),
    171px 1213px var(--color),
    1646px 1200px var(--color),
    1182px 157px var(--color),
    1217px 1015px var(--color),
    1936px 807px var(--color),
    1743px 890px var(--color),
    1825px 27px var(--color),
    545px 21px var(--color),
    1895px 993px var(--color),
    1631px 1180px var(--color),
    1634px 411px var(--color),
    1998px 886px var(--color),
    1479px 906px var(--color),
    528px 970px var(--color),
    861px 1238px var(--color),
    881px 361px var(--color),
    960px 916px var(--color),
    280px 950px var(--color),
    1310px 986px var(--color),
    768px 1796px var(--color),
    1187px 980px var(--color),
    612px 1279px var(--color),
    377px 1876px var(--color),
    1170px 736px var(--color),
    1331px 733px var(--color),
    450px 233px var(--color),
    1875px 1317px var(--color),
    1868px 1315px var(--color),
    1874px 1018px var(--color),
    1062px 1949px var(--color),
    1266px 797px var(--color),
    1087px 443px var(--color),
    1984px 369px var(--color),
    1209px 1726px var(--color),
    1626px 501px var(--color),
    1991px 986px var(--color),
    1467px 356px var(--color),
    1163px 964px var(--color),
    1671px 1944px var(--color),
    374px 1634px var(--color),
    1556px 820px var(--color),
    1800px 1600px var(--color),
    1314px 1935px var(--color),
    1174px 1293px var(--color);
  opacity: 0.25;
}
.stars.accelerate div:nth-child(3) {
  animation-duration: 20s !important;
  opacity: 0.5;
}

@keyframes risingStarsAnination {
  0% {
    transform: translateZ(0) translateY(0);
  }

  to {
    transform: translateZ(0) translateY(-2000px);
  }
}
</style>